/**
 * @packageDocumentation
 * @module api.functional.$case
 * @nestia Generated by Nestia - https://github.com/samchon/nestia
 */
//================================================================
import type { IConnection } from "@nestia/fetcher";
import { PlainFetcher } from "@nestia/fetcher/lib/PlainFetcher";
import type { Format } from "typia/lib/tags/Format";

import type { CreateCaseDto } from "../../structures/CreateCaseDto";

export * as employees from "./employees";
export * as similarCompanies from "./similarCompanies";
export * as attempts from "./attempts";
export * as events from "./events";
export * as member from "./member";

/**
 * @controller CaseController.getCases
 * @path GET /case
 * @nestia Generated by Nestia - https://github.com/samchon/nestia
 */
export async function getCases(connection: IConnection): Promise<getCases.Output> {
    return PlainFetcher.fetch(connection, {
        ...getCases.METADATA,
        template: getCases.METADATA.path,
        path: getCases.path(),
    });
}
export namespace getCases {
    export type Output = {
        id: number;
        name: string;
        description: null | string;
        website: null | string;
        linkedin_url: null | string;
        logo_url: null | string;
        dealroom_id: null | number;
        created_at: string & Format<"date-time">;
        user_id: number;
    }[];

    export const METADATA = {
        method: "GET",
        path: "/case",
        request: null,
        response: {
            type: "application/json",
            encrypted: false,
        },
        status: null,
    } as const;

    export const path = () => "/case";
}

/**
 * @controller CaseController.getCase
 * @path GET /case/:id
 * @nestia Generated by Nestia - https://github.com/samchon/nestia
 */
export async function getCase(connection: IConnection, id: number): Promise<getCase.Output> {
    return PlainFetcher.fetch(connection, {
        ...getCase.METADATA,
        template: getCase.METADATA.path,
        path: getCase.path(id),
    });
}
export namespace getCase {
    export type Output = {
        id: number;
        name: string;
        description: null | string;
        website: null | string;
        linkedin_url: null | string;
        logo_url: null | string;
        dealroom_id: null | number;
        created_at: string & Format<"date-time">;
        user_id: number;
    };

    export const METADATA = {
        method: "GET",
        path: "/case/:id",
        request: null,
        response: {
            type: "application/json",
            encrypted: false,
        },
        status: null,
    } as const;

    export const path = (id: number) => `/case/${encodeURIComponent(id ?? "null")}`;
}

/**
 * @controller CaseController.createCase
 * @path POST /case
 * @nestia Generated by Nestia - https://github.com/samchon/nestia
 */
export async function createCase(connection: IConnection, data: createCase.Input): Promise<createCase.Output> {
    return PlainFetcher.fetch(
        {
            ...connection,
            headers: {
                ...connection.headers,
                "Content-Type": "application/json",
            },
        },
        {
            ...createCase.METADATA,
            template: createCase.METADATA.path,
            path: createCase.path(),
        },
        data,
    );
}
export namespace createCase {
    export type Input = CreateCaseDto;
    export type Output = {
        id: number;
        name: string;
        description: null | string;
        website: null | string;
        linkedin_url: null | string;
        logo_url: null | string;
        dealroom_id: null | number;
        created_at: string & Format<"date-time">;
        user_id: number;
    };

    export const METADATA = {
        method: "POST",
        path: "/case",
        request: {
            type: "application/json",
            encrypted: false,
        },
        response: {
            type: "application/json",
            encrypted: false,
        },
        status: null,
    } as const;

    export const path = () => "/case";
}

/**
 * @controller CaseController.regenerate
 * @path POST /case/:id/regenerate
 * @nestia Generated by Nestia - https://github.com/samchon/nestia
 */
export async function regenerate(connection: IConnection, id: number): Promise<void> {
    return PlainFetcher.fetch(connection, {
        ...regenerate.METADATA,
        template: regenerate.METADATA.path,
        path: regenerate.path(id),
    });
}
export namespace regenerate {
    export const METADATA = {
        method: "POST",
        path: "/case/:id/regenerate",
        request: null,
        response: {
            type: "application/json",
            encrypted: false,
        },
        status: null,
    } as const;

    export const path = (id: number) => `/case/${encodeURIComponent(id ?? "null")}/regenerate`;
}
