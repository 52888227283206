import { useQuery } from "@tanstack/react-query";
import sdk from "../../sdk";
import { getApiConnection } from "../app/api.ts";

export const useMemoTopicQuery = (caseId: number, topic: string, blockRefetch = false) => {
    return useQuery({
        queryKey: ["memoTopic", caseId, topic],
        queryFn: async () => {
            return await sdk.functional.memo.cases.memoTopics.getMemoTopics(getApiConnection(), caseId, topic);
        },
        refetchInterval: (topic) => {
            // Keep refetching while pending, but not while regenerating
            if (blockRefetch) return false;
            if (topic?.is_pending) return 1000;
            return false;
        },
    });
};
