import { Button, Card, Heading, HStack, Icon, SkeletonText, Spacer, Stack } from "@chakra-ui/react";
import { faQuoteRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FC } from "react";
import { CopyToClipboard } from "../../../components/misc/copyToClipboard/CopyToClipboard.tsx";
import { StyledMarkdown } from "../../../components/misc/styledMarkdown/StyledMarkdown.tsx";
import { SourcesPopover } from "../../../components/rag/sources/SourcePopover/SourcePopover.tsx";
import { aggregateSources } from "../../../lib/chat/aggregateSources.ts";
import { useMemoTopicQuery } from "../../../lib/queries/useMemoTopicQuery.ts";
import { TopicInfo } from "../../../sdk/structures/TopicInfo.ts";

interface MemoProps {
    caseId: number;
    topic: TopicInfo;
    blockRefetch?: boolean;
}
export const MemoTopic: FC<MemoProps> = ({ caseId, topic, blockRefetch }) => {
    const $memoTopic = useMemoTopicQuery(caseId, topic.key, blockRefetch);
    const sources = aggregateSources($memoTopic.data?.text ?? "");

    return (
        <Card variant={"flat"}>
            <Stack gap={4}>
                <HStack alignItems={"start"}>
                    <Heading as={"h2"} variant={"h2"}>
                        {topic.display_name}
                    </Heading>
                    <Spacer />
                    {$memoTopic.data?.is_pending === false && <CopyToClipboard content={$memoTopic.data.text} />}
                </HStack>

                <SkeletonText isLoaded={$memoTopic.data && $memoTopic.data?.text.length > 0} fadeDuration={1}>
                    <StyledMarkdown>{$memoTopic.data?.text}</StyledMarkdown>
                </SkeletonText>

                {/*Topic exists and is NOT pending -> show sources*/}
                {$memoTopic.data?.is_pending === false && (
                    <HStack flexWrap={"wrap"} justify={"space-between"}>
                        {sources.length > 0 && (
                            <SourcesPopover heading={"Sources"} chunkIds={sources} trigger={"hover"}>
                                <Button
                                    bg={"gray.300"}
                                    _hover={{ bg: "gray.400" }}
                                    aria-label={"all source citation button"}
                                    leftIcon={<Icon as={FontAwesomeIcon} icon={faQuoteRight} />}
                                >
                                    Show sources
                                </Button>
                            </SourcesPopover>
                        )}
                    </HStack>
                )}
            </Stack>
        </Card>
    );
};
