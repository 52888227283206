/**
 * @packageDocumentation
 * @module api.functional.user.me
 * @nestia Generated by Nestia - https://github.com/samchon/nestia
 */
//================================================================
import type { IConnection } from "@nestia/fetcher";
import { PlainFetcher } from "@nestia/fetcher/lib/PlainFetcher";
import type { Format } from "typia/lib/tags/Format";

/**
 * @controller UserController.getCurrentUser
 * @path GET /user/me
 * @nestia Generated by Nestia - https://github.com/samchon/nestia
 */
export async function getCurrentUser(connection: IConnection): Promise<getCurrentUser.Output> {
    return PlainFetcher.fetch(connection, {
        ...getCurrentUser.METADATA,
        template: getCurrentUser.METADATA.path,
        path: getCurrentUser.path(),
    });
}
export namespace getCurrentUser {
    export type Output = {
        id: number;
        name: string;
        created_at: string & Format<"date-time">;
        sub: string;
        email: null | string;
        picture: null | string;
    };

    export const METADATA = {
        method: "GET",
        path: "/user/me",
        request: null,
        response: {
            type: "application/json",
            encrypted: false,
        },
        status: null,
    } as const;

    export const path = () => "/user/me";
}
