/**
 * @packageDocumentation
 * @module api.functional.$case.member
 * @nestia Generated by Nestia - https://github.com/samchon/nestia
 */
//================================================================
import type { IConnection } from "@nestia/fetcher";
import { PlainFetcher } from "@nestia/fetcher/lib/PlainFetcher";

import type { AddMemberDto } from "../../../structures/AddMemberDto";
import type { useridnumbernamestringemailstringnullpicturestringnullidnumbercreated_atDateuser_idnumbercase_idnumber } from "../../../structures/useridnumbernamestringemailstringnullpicturestringnullidnumbercreated_atDateuser_idnumbercase_idnumber";

/**
 * @controller MemberController.getMembers
 * @path GET /case/:caseId/member
 * @nestia Generated by Nestia - https://github.com/samchon/nestia
 */
export async function getMembers(connection: IConnection, caseId: number): Promise<getMembers.Output> {
    return PlainFetcher.fetch(connection, {
        ...getMembers.METADATA,
        template: getMembers.METADATA.path,
        path: getMembers.path(caseId),
    });
}
export namespace getMembers {
    export type Output =
        useridnumbernamestringemailstringnullpicturestringnullidnumbercreated_atDateuser_idnumbercase_idnumber[];

    export const METADATA = {
        method: "GET",
        path: "/case/:caseId/member",
        request: null,
        response: {
            type: "application/json",
            encrypted: false,
        },
        status: null,
    } as const;

    export const path = (caseId: number) => `/case/${encodeURIComponent(caseId ?? "null")}/member`;
}

/**
 * @controller MemberController.addMember
 * @path POST /case/:caseId/member
 * @nestia Generated by Nestia - https://github.com/samchon/nestia
 */
export async function addMember(connection: IConnection, caseId: number, data: addMember.Input): Promise<void> {
    return PlainFetcher.fetch(
        {
            ...connection,
            headers: {
                ...connection.headers,
                "Content-Type": "application/json",
            },
        },
        {
            ...addMember.METADATA,
            template: addMember.METADATA.path,
            path: addMember.path(caseId),
        },
        data,
    );
}
export namespace addMember {
    export type Input = AddMemberDto;

    export const METADATA = {
        method: "POST",
        path: "/case/:caseId/member",
        request: {
            type: "application/json",
            encrypted: false,
        },
        response: {
            type: "application/json",
            encrypted: false,
        },
        status: null,
    } as const;

    export const path = (caseId: number) => `/case/${encodeURIComponent(caseId ?? "null")}/member`;
}

/**
 * @controller MemberController.removeMember
 * @path DELETE /case/:caseId/member/:memberId
 * @nestia Generated by Nestia - https://github.com/samchon/nestia
 */
export async function removeMember(connection: IConnection, caseId: number, memberId: number): Promise<void> {
    return PlainFetcher.fetch(connection, {
        ...removeMember.METADATA,
        template: removeMember.METADATA.path,
        path: removeMember.path(caseId, memberId),
    });
}
export namespace removeMember {
    export const METADATA = {
        method: "DELETE",
        path: "/case/:caseId/member/:memberId",
        request: null,
        response: {
            type: "application/json",
            encrypted: false,
        },
        status: null,
    } as const;

    export const path = (caseId: number, memberId: number) =>
        `/case/${encodeURIComponent(caseId ?? "null")}/member/${encodeURIComponent(memberId ?? "null")}`;
}
