import { Box, Button, Icon, useToast } from "@chakra-ui/react";
import { faCopy } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FC, useRef } from "react";
import { StyledMarkdown } from "../styledMarkdown/StyledMarkdown.tsx";

interface CopyToClipboardProps {
    /**
     * The content to copy to the clipboard
     */
    content: string;
}

export const CopyToClipboard: FC<CopyToClipboardProps> = ({ content }) => {
    // Clean up sources
    const cleanMessage = content.replaceAll(/\s?\[source\]\([a-z0-9#-]+\)\s?/gi, "");
    const divRef = useRef<HTMLDivElement>(null);

    const copyToClipboard = async () => {
        if (!divRef.current) return;
        const html = divRef.current.innerHTML;

        await navigator.clipboard.write([
            new ClipboardItem({
                "text/html": new Blob([html], { type: "text/html" }),
                "text/plain": new Blob([cleanMessage], { type: "text/plain" }),
            }),
        ]);

        // await navigator.clipboard.writeText(cleanMessage);
        copiedToClipboardToast();
    };

    const copiedToClipboardToast = useToast({
        title: "Text copied to clipboard",
        status: "success",
        position: "top-right",
    });

    return (
        <>
            <Box ref={divRef} display={"none"}>
                <StyledMarkdown>{cleanMessage}</StyledMarkdown>
            </Box>
            <Button
                onClick={() => copyToClipboard()}
                color={"gray.800"}
                bg={"gray.300"}
                variant={"ghost"}
                leftIcon={<Icon as={FontAwesomeIcon} icon={faCopy} />}
            >
                Copy text
            </Button>
        </>
    );
};
