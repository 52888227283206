import { useQuery } from "@tanstack/react-query";
import sdk from "../../sdk";
import { getApiConnection } from "../app/api.ts";

export const useMemoQuery = (caseId: number, blockRefetch = false) => {
    return useQuery({
        queryKey: ["memo", caseId],
        queryFn: async () => {
            return await sdk.functional.memo.cases.memo.getMemo(getApiConnection(), caseId);
        },
        refetchInterval: (memo) => {
            // Keep refetching while pending, but not while regenerating
            if (blockRefetch) return false;
            if (memo?.is_pending) return 1000;
            return false;
        },
    });
};
