import { useCallback, useEffect, useState } from "react";

export const useScrollSpy = () => {
    const margin = 25;

    const [elements, setElements] = useState<Array<HTMLElement>>([]);

    const [activeSection, setActiveSection] = useState<string | null>(null);

    const bindElement = useCallback((el: HTMLElement | null) => {
        if (!el) return;
        if (!el.id) return console.error("Element must have an ID");
        setElements((prev) => [
            // Remove old element with this id, and add this one
            ...prev.filter((e) => e.id !== el.id),
            el,
        ]);
    }, []);

    const scrollToElement = (id: string) => {
        // Find the element with the given ID in our list
        const el = elements.find((e) => e.id === id);
        if (!el) return console.warn(`Element with #${id} not found (has been it bound using bindElement?)`);

        window.scrollTo({
            top: el.getBoundingClientRect().top - margin + 1 + window.scrollY,
            behavior: "smooth",
        });
    };

    useEffect(() => {
        const onScroll = () => {
            // Go through each element and get its scroll position
            const sections = elements
                .map((el) => {
                    return {
                        el,
                        top: el.getBoundingClientRect().top,
                    };
                })

                // Sort: Negative top always wins over positive
                // Sort: Both negative: Bigger wins
                // Sort: Both positive: Smaller wins
                .sort((a, b) => {
                    if (a.top < margin && b.top < margin) return b.top - a.top;
                    return a.top - b.top;
                });

            // Got matching sections, return the first one
            if (sections.length) setActiveSection(sections[0].el.id);
            else setActiveSection(null);
        };

        onScroll();
        window.addEventListener("scroll", onScroll);
        return () => window.removeEventListener("scroll", onScroll);
    });

    return {
        /**
         * Pass this to each elements ref attribute to register them
         */
        bindElement,
        /**
         * The ID currently active section, or null if none
         */
        activeSection,
        /**
         * Smoothly scroll to an element, by id
         */
        scrollToElement,
    };
};
