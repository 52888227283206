import {
    Box,
    Button,
    Card,
    CardBody,
    CardHeader,
    Heading,
    HStack,
    Text,
    Spinner,
    Stack,
    useToast,
} from "@chakra-ui/react";
import { FC, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { SideNavLayout } from "../../components/layouts/sideNavLayout/SideNavLayout.tsx";
import { EmptyState } from "../../components/misc/emptyState/EmptyState.tsx";
import { useRegenerateMemoMutation } from "../../lib/mutations/useRegenerateMemoMutation.ts";
import { useMemoQuery } from "../../lib/queries/useMemoQuery.ts";
import { useTopicsQuery } from "../../lib/queries/useTopicsQuery.ts";
import { useScrollSpy } from "../../lib/util/useScrollSpy.ts";
import { Memo } from "./components/Memo.tsx";
import { MemoTopic } from "./components/MemoTopic.tsx";
import memoPlaceholder from "../../assets/images/memo-placeholder.svg";

interface MemoPageProps {}
export const MemoPage: FC<MemoPageProps> = ({}) => {
    const { caseId } = useParams<{ caseId: string }>();
    const toast = useToast();

    const $regenerate = useRegenerateMemoMutation(parseInt(caseId ?? ""));
    const $topics = useTopicsQuery();
    const $memo = useMemoQuery(parseInt(caseId ?? ""), $regenerate.isLoading);

    const memosRef = useRef<HTMLDivElement>(null);
    const { bindElement, activeSection, scrollToElement } = useScrollSpy();

    // notify user when memo is successfully regenerated
    useEffect(() => {
        if (!$regenerate.isSuccess) return;
        toast({
            title: "Memo regenerated successfully",
            status: "success",
            duration: 5000,
            isClosable: true,
        });
    }, [$regenerate.isSuccess, toast]);

    // loading memo
    if ($memo.isLoading)
        return (
            <SideNavLayout>
                <Spinner />
            </SideNavLayout>
        );

    // not loading, no memo
    if (!$memo.data) {
        return (
            <SideNavLayout>
                <EmptyState flexGrow={1} minH={"full"} maxW={"400px"} imageSrc={memoPlaceholder}>
                    <Text py={3} fontSize={"lg"}>
                        There is no memo for this case yet
                    </Text>
                    <Button
                        colorScheme={"purple"}
                        isLoading={$regenerate.isLoading}
                        onClick={() => $regenerate.mutate()}
                    >
                        Generate memo
                    </Button>
                </EmptyState>
            </SideNavLayout>
        );
    }

    // we have a memo, pending or not
    return (
        <SideNavLayout>
            <HStack gap={12} w={"full"} align={"start"}>
                <Stack gap={8} flexGrow={1}>
                    {/* Memo */}
                    <Box ref={bindElement} id={"memo"}>
                        <Memo
                            memo={$memo.data}
                            onRegenerate={() => $regenerate.mutate()}
                            isLoading={$memo.data.is_pending || $regenerate.isLoading}
                        />
                    </Box>

                    {/*Topics*/}
                    {/*We either have a memo, we are regenerating topics, or the memo is being regenerated*/}
                    {$topics.data && (
                        <Card
                            variant={"subtle"}
                            // onClick={() => topicsDisclosure.onToggle()}
                            // as={"button"}
                            display={"block"}
                            textAlign={"left"}
                            role={"button"}
                        >
                            <CardHeader pb={0}>
                                <HStack w={"full"} alignItems={"center"} justifyContent={"space-between"}>
                                    <Heading as={"h3"} variant={"h3"} color={"gray.600"} flexGrow={1}>
                                        Detailed topics
                                    </Heading>
                                    {/* Arrow for if this is made collapsible again */}
                                    {/*<FontAwesomeIcon*/}
                                    {/*    icon={faChevronDown}*/}
                                    {/*    style={{*/}
                                    {/*        transform: topicsDisclosure.isOpen ? "rotate(180deg)" : undefined,*/}
                                    {/*        transition: "transform 0.2s ease-in-out",*/}
                                    {/*    }}*/}
                                    {/*/>*/}
                                </HStack>
                            </CardHeader>

                            {/* Collapse for if this is made collapsible again */}
                            {/*<Collapse in={topicsDisclosure.isOpen}>*/}
                            {/*<CardBody cursor={"default"} onClick={(e) => e.stopPropagation()} pt={6}>*/}
                            <CardBody cursor={"default"} pt={6}>
                                <Stack flexGrow={1} gap={8}>
                                    {/* Memo cards */}
                                    <Stack ref={memosRef} gap={8}>
                                        {$topics.data.map((topic) => (
                                            <Box ref={bindElement} key={topic.id} id={topic.key}>
                                                <MemoTopic
                                                    topic={topic}
                                                    caseId={parseInt(caseId ?? "")}
                                                    blockRefetch={$regenerate.isLoading}
                                                />
                                            </Box>
                                        ))}
                                    </Stack>
                                </Stack>
                            </CardBody>
                        </Card>
                    )}
                </Stack>

                {/*Table of Contents*/}
                <Stack w={"64"} flexShrink={0} pos={"sticky"} top={"10"}>
                    <Button
                        variant={"menuButton"}
                        isActive={activeSection === "memo"}
                        onClick={() => scrollToElement("memo")}
                    >
                        Memo
                    </Button>
                    {$topics.data?.map((topic) => (
                        <Button
                            variant={"menuButton"}
                            isActive={activeSection === topic.key}
                            key={topic.id}
                            onClick={() => scrollToElement(topic.key)}
                        >
                            {topic.display_name}
                        </Button>
                    ))}
                </Stack>
            </HStack>
        </SideNavLayout>
    );
};
