import { EventSourceMessage, fetchEventSource } from "@microsoft/fetch-event-source";
import { useMutation } from "@tanstack/react-query";
import { getMemo } from "../../sdk/functional/memo/cases/memo";
import { getMemoTopics } from "../../sdk/functional/memo/cases/memoTopics";
import { getAuthHeaders } from "../app/api.ts";
import { queryClient } from "../app/queryClient.ts";

export const useRegenerateMemoMutation = (caseId: number) => {
    return useMutation({
        mutationKey: ["regenerateMemo", caseId],
        mutationFn: async () => {
            const onMemoCreate = (memo: getMemo.Output) => {
                // console.log("onMemoCreate", memo);
                queryClient.setQueryData<getMemo.Output>(["memo", memo.case_id], () => memo);
            };

            const onMemoAddText = (data: { caseId: number; topic: string; text: string }) => {
                // console.log("onMemoAddText", data);
                queryClient.setQueryData<getMemoTopics.Output>(["memo", data.caseId], (memo) => {
                    if (!memo) return memo;
                    return { ...memo, text: memo.text + data.text }; // Force a re-render by creating a new object
                });
            };

            const onMemoComplete = (memo: getMemo.Output) => {
                // console.log("onMemoComplete", memo);
                queryClient.setQueryData<getMemo.Output>(["memo", memo.case_id], () => memo);
            };

            const onTopicCreate = (topic: getMemoTopics.Output) => {
                // console.log("onTopicCreate", topic);
                queryClient.setQueryData<getMemoTopics.Output>(["memoTopic", topic.case_id, topic.topic], () => topic);
            };

            const onTopicAddText = (data: { caseId: number; topic: string; text: string }) => {
                // console.log("onTopicAddText", ["memoTopic", data.caseId, data.topic], data);
                queryClient.setQueryData<getMemoTopics.Output>(["memoTopic", data.caseId, data.topic], (topic) => {
                    if (!topic) return topic; // we didn't have it yet, but now we do
                    return { ...topic, text: topic.text + data.text }; // Force a re-render by creating a new object
                });
            };

            const onTopicComplete = (topic: getMemoTopics.Output) => {
                // console.log("onTopicComplete", topic);
                queryClient.setQueryData<getMemoTopics.Output>(["memoTopic", topic.case_id, topic.topic], () => topic);
            };

            const onMessage = async (msg: EventSourceMessage) => {
                if (msg.event === "memo.create") onMemoCreate(JSON.parse(msg.data));
                if (msg.event === "memo.addText") onMemoAddText(JSON.parse(msg.data));
                if (msg.event === "memo.complete") onMemoComplete(JSON.parse(msg.data));
                if (msg.event === "memoTopic.create") onTopicCreate(JSON.parse(msg.data));
                if (msg.event === "memoTopic.addText") onTopicAddText(JSON.parse(msg.data));
                if (msg.event === "memoTopic.complete") onTopicComplete(JSON.parse(msg.data));
                if (msg.event === "error") throw new Error(msg.data);
            };

            await fetchEventSource(import.meta.env.VITE_API_BASE + `/memo/cases/${caseId}/regenerate`, {
                method: "POST",
                headers: { "Content-Type": "application/json", ...getAuthHeaders() },
                onmessage: onMessage,
                openWhenHidden: true, // critical so it doesnt re-post on tab focus
                onerror: (err: unknown) => {
                    console.warn("error", err);
                    throw err; // Stop fetchEventSource from retrying
                },
            });
        },

        retry: false,
    });
};
