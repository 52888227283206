import {
    Button,
    Card,
    Heading,
    HStack,
    Icon,
    IconButton,
    SkeletonText,
    Spacer,
    Spinner,
    Stack,
} from "@chakra-ui/react";
import { faRotate } from "@fortawesome/free-solid-svg-icons/faRotate";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FC } from "react";
import { CopyToClipboard } from "../../../components/misc/copyToClipboard/CopyToClipboard.tsx";
import { StyledMarkdown } from "../../../components/misc/styledMarkdown/StyledMarkdown.tsx";
import { getMemo } from "../../../sdk/functional/memo/cases/memo";

interface MemoProps {
    memo?: getMemo.Output;
    isLoading?: boolean;
    onRegenerate?: () => void;
}
export const Memo: FC<MemoProps> = ({ memo, isLoading, onRegenerate }) => {
    return (
        <Card variant={"flat"}>
            <Stack>
                {/*Title, regen / copy buttons*/}
                <HStack>
                    {/* card title */}
                    <Heading as={"h1"} variant={"h1"} mb={4}>
                        Memo
                    </Heading>
                    <Spacer />

                    {memo && !isLoading && <CopyToClipboard content={memo.text} />}

                    <Button
                        rounded={"full"}
                        colorScheme={"purple"}
                        aria-label={"Regenerate memo"}
                        leftIcon={<Icon as={FontAwesomeIcon} icon={faRotate} />}
                        onClick={onRegenerate}
                        isLoading={isLoading}
                    >
                        Regenerate memo
                    </Button>
                    {/*<CopyToClipboard content={memo.text} />*/}
                </HStack>

                <SkeletonText isLoaded={memo && memo.text.length > 0} fadeDuration={1}>
                    <StyledMarkdown>{memo?.text}</StyledMarkdown>
                </SkeletonText>
            </Stack>
        </Card>
    );
};
