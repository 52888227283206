/**
 * @packageDocumentation
 * @module api.functional.memo.cases
 * @nestia Generated by Nestia - https://github.com/samchon/nestia
 */
//================================================================
import type { IConnection } from "@nestia/fetcher";
import { PlainFetcher } from "@nestia/fetcher/lib/PlainFetcher";

export * as memoTopics from "./memoTopics";
export * as memo from "./memo";

/**
 * @controller MemoController.regenerate
 * @path POST /memo/cases/:caseId/regenerate
 * @nestia Generated by Nestia - https://github.com/samchon/nestia
 */
export async function regenerate(connection: IConnection, caseId: number): Promise<regenerate.Output> {
    return PlainFetcher.fetch(connection, {
        ...regenerate.METADATA,
        template: regenerate.METADATA.path,
        path: regenerate.path(caseId),
    });
}
export namespace regenerate {
    export type Output = any;

    export const METADATA = {
        method: "POST",
        path: "/memo/cases/:caseId/regenerate",
        request: null,
        response: {
            type: "application/json",
            encrypted: false,
        },
        status: null,
    } as const;

    export const path = (caseId: number) => `/memo/cases/${encodeURIComponent(caseId ?? "null")}/regenerate`;
}
