/**
 * @packageDocumentation
 * @module api.functional.memo.cases.memoTopics
 * @nestia Generated by Nestia - https://github.com/samchon/nestia
 */
//================================================================
import type { IConnection } from "@nestia/fetcher";
import { PlainFetcher } from "@nestia/fetcher/lib/PlainFetcher";
import type { Format } from "typia/lib/tags/Format";

/**
 * @controller MemoController.getMemoTopics
 * @path GET /memo/cases/:caseId/memoTopics/:topic
 * @nestia Generated by Nestia - https://github.com/samchon/nestia
 */
export async function getMemoTopics(
    connection: IConnection,
    caseId: number,
    topic: string,
): Promise<getMemoTopics.Output> {
    return PlainFetcher.fetch(connection, {
        ...getMemoTopics.METADATA,
        template: getMemoTopics.METADATA.path,
        path: getMemoTopics.path(caseId, topic),
    });
}
export namespace getMemoTopics {
    export type Output = {
        id: number;
        created_at: string & Format<"date-time">;
        user_id: number;
        case_id: number;
        text: string;
        topic: string;
        is_pending: boolean;
    };

    export const METADATA = {
        method: "GET",
        path: "/memo/cases/:caseId/memoTopics/:topic",
        request: null,
        response: {
            type: "application/json",
            encrypted: false,
        },
        status: null,
    } as const;

    export const path = (caseId: number, topic: string) =>
        `/memo/cases/${encodeURIComponent(caseId ?? "null")}/memoTopics/${encodeURIComponent(topic ?? "null")}`;
}
